
module.exports =
  metaInfo:
    title: 'Как экономить на авто, бензине и обслуживании'
    meta: [
      { name: 'description', content: 'Как экономить на авто, бензине, запчастях и обслуживании: ТОП сногсшибательно полезных советов в эксклюзивной Энциклопедии Экономии ХаниМани!' }
      { name: 'keywords', content: 'как экономить на авто, экономия на авто, экономия на бензине, как экономить бензин' }
      { name: 'human_title', content: 'Как экономить на авто, бензине и обслуживании' }
      { name: 'human_subtitle', content: 'Десятки советов об экономии на автомобиле' }
    ]
